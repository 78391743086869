import React from 'react'
import { graphql } from 'gatsby'
import ReactMarkdown from 'react-markdown'
import { Container, Grid } from 'semantic-ui-react'
import Layout from '../components/Layout'
import Breadcrumbs from "../components/Breadcrumbs"

export const query = graphql`
  query {
    page:strapiLocation(id: {ne: ""}) {
      Title
      Address
      MapEmbed
    }
  }
`

const LocationPage = ({ data, location }) => {
  const page = data.page
  return (
    <Layout location={location}>
      <Grid stackable>
        <Grid.Row>
          <Container className="maininner">
            <Breadcrumbs className="content-breadcrumbs" PageTitle={page.Title} />
            <article className="content-article">
              <header>
                <h1>{page.Title}</h1>
              </header>
              <div>
                <ReactMarkdown source={page.Address} />
                <ReactMarkdown escapeHtml={false}>{page.MapEmbed}</ReactMarkdown>
              </div>
            </article>
          </Container>
        </Grid.Row>
      </Grid>
    </Layout>
  )
}

export default LocationPage
